
import Macy from 'macy';

// Enable when DOM is loaded
window.addEventListener('DOMContentLoaded', function() {

    if(document.body.contains(document.getElementById('masonry'))){

        // Masonry grid
        var macy = Macy({
            container: '.masonry-grid',
            trueOrder: true,
            waitForImages: true,
            margin: 5,
            columns: 3,
            /*breakAt: {
                375: {
                    columns: 3,
                    margin: {
                        x: 5,
                    },
                }
            }*/
        });

        macy.on(macy.constants.EVENT_IMAGE_COMPLETE, function (ctx) {
            console.log('all images have loaded');
        });

        macy.on(macy.constants.EVENT_INITIALIZED, function (ctx) {
            console.log('all images have loaded');
        });

    }

});