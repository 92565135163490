// Initialisation de Swiper
import Swiper from 'swiper/bundle';
/*import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay, Scrollbar, EffectFade } from 'swiper/modules';
Swiper.use([Navigation, Pagination, Autoplay, Scrollbar, EffectFade]);*/

window.swiper = Swiper;
/*
let sliders = Array.prototype.slice.call(
	document.querySelectorAll('.swiper-container[data-slug]')
);
*/



// Open modal on .masonry-grid .links (photographer page)
document.addEventListener("DOMContentLoaded", function () {

    const modalEl = document.getElementById('modalPhotographer');
    const bootstrapModal = new Modal(modalEl);
    const swiperEl = document.querySelector('.modal .swiper-container');

    const swiperInstance = new Swiper('.swiper', {
        direction: 'horizontal',
        loop: false,
        slidesPerView: 'auto',
        spaceBetween: 8,
        speed: 400,
        autoHeight: false,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        scrollbar: {
            el: '.swiper-scrollbar',
        },
        autoplay: false,
        //effect: 'fade',
    });

    // Photographer
    const galleryLinks = document.querySelectorAll('.masonry-grid .links');
    galleryLinks.forEach(link => {
        link.addEventListener('click', function (event) {
            event.preventDefault(); // Prevent default link behavior

            // Get the index of the slide from data attribute
            const swiperIndex = parseInt(link.dataset.swiperIndex, 10);

            // Open the modal
            bootstrapModal.show();

            // Fullscreen logic for mobile
            if (window.innerWidth < 768) {
                openFullscreen(modalEl);
            }

            // Wait for modal to show before navigating to the slide
            modalEl.addEventListener('shown.bs.modal', function () {
                swiperInstance.slideTo(swiperIndex);
            }, { once: true }); // Use `{ once: true }` to ensure the event listener is removed after execution
        });
    });

    // Portfolio (Fiche)
    const portfoliosLinks = document.querySelectorAll('.block--image_gallery_block .links');
    portfoliosLinks.forEach(link => {
        link.addEventListener('click', function (event) {
            event.preventDefault(); // Prevent default link behavior

            // Get the index of the slide from data attribute
            const swiperIndex = parseInt(link.dataset.swiperIndex, 10);

            // Open the modal
            bootstrapModal.show();

            // Fullscreen logic for mobile
            if (window.innerWidth < 768) {
                openFullscreen(modalEl);
            }

            // Wait for modal to show before navigating to the slide
            modalEl.addEventListener('shown.bs.modal', function () {
                swiperInstance.slideTo(swiperIndex);
            }, { once: true }); // Use `{ once: true }` to ensure the event listener is removed after execution
        });
    });

    // Portfolio (Fiche - only main image)
    const albumHeaderLinks = document.querySelectorAll('.album-header .links');
    albumHeaderLinks.forEach(link => {
        link.addEventListener('click', function (event) {
            event.preventDefault(); // Prevent default link behavior

            // Get the index of the slide from data attribute
            const swiperIndex = parseInt(link.dataset.swiperIndex, 10);

            // Open the modal
            bootstrapModal.show();

            // Fullscreen logic for mobile
            if (window.innerWidth < 768) {
                openFullscreen(modalEl);
            }

            // Wait for modal to show before navigating to the slide
            modalEl.addEventListener('shown.bs.modal', function () {
                swiperInstance.slideTo(swiperIndex);
            }, { once: true }); // Use `{ once: true }` to ensure the event listener is removed after execution
        });
    });

    // Fullscreen API for mobile
    function openFullscreen(element) {
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.webkitRequestFullscreen) { // Safari
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) { // IE/Edge
            element.msRequestFullscreen();
        }
    }

    function closeFullscreen() {
        // Check if the document is in fullscreen mode before trying to exit
        if (document.fullscreenElement || document.webkitFullscreenElement || document.msFullscreenElement) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) { // Safari
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { // IE/Edge
                document.msExitFullscreen();
            }
        }
    }

    // Optional: Exit fullscreen when modal is closed
    modalEl.addEventListener('hidden.bs.modal', function () {
        closeFullscreen();
    });
});
