document.addEventListener('DOMContentLoaded', function() {
    (function (window, document) {

        // Enable obfuscation link
        let links = Array.prototype.slice.call(
            document.querySelectorAll('[data-href]')
        );

        links.forEach(function (link) {
            let link_href = link.dataset.href;
            let link_target = link.dataset.target || '_self';

            link.addEventListener('click', function(event) {
                window.location.href = link_href;
            });
        });

    })(window, document);
});
