// Init
(function (window, document) {

    const breakpoints = {
        desktop: 1292,
        laptop: 1023,
        tablet: 768,
        mobile: 0
    };

    const deviceClasses = ['desktop', 'laptop', 'tablet', 'mobile'];

    function getDeviceClass(width) {
        if (width >= breakpoints.desktop) {
            return 'desktop';
        } else if (width >= breakpoints.laptop) {
            return 'laptop';
        } else if (width >= breakpoints.tablet) {
            return 'tablet';
        } else {
            return 'mobile';
        }
    }

    const updateClass = () => {
        const width = window.innerWidth;
        const newClass = getDeviceClass(width);
        window.lastDevice = newClass;

        // Remove all device classes
        document.body.classList.remove(...deviceClasses);

        // Add the new class
        document.body.classList.add(newClass);
    };

    window.addEventListener('resize', updateClass);
    updateClass();

})(window, document);

